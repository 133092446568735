import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {map, Subject, takeUntil} from 'rxjs';
import {ServiceRegistrationService} from '../../../../core/service/service-registration.service';
import {EvaluationFormControls} from '../../../../shared/model/evaluation-form-control.model';
import {
  EvaluationQuestion,
  EvaluationQuestionSection,
  EvaluationQuestionType,
  IEvaluation
} from '../../../../shared/model/evaluation-model';
import {IOpportunity} from '../../../../shared/model/opportunity.model';
import {IServiceRegistration} from '../../../../shared/model/service-registration.model';

@Component({
  selector: 'app-evaluation-experience',
  templateUrl: './evaluation-experience.component.html',
  styleUrls: ['./evaluation-experience.component.scss']
})
export class EvaluationExperienceComponent implements OnInit, OnDestroy {
  @Input()
  public service!: IServiceRegistration;

  @Input()
  public opportunity!: IOpportunity;

  @Output()
  public evaluationComplete: EventEmitter<boolean> = new EventEmitter<boolean>();
  public experienceForm!: FormGroup;
  public evaluations: IEvaluation[] = [];
  public hasRetrievedEvals: boolean = false;

  public evaluationQuestions: EvaluationQuestion[] = [
    { id: 'ST_EXP_1', text: 'Supervisor', type: EvaluationQuestionType.STUDENT, section: EvaluationQuestionSection.EXPERIENCE, order: '1' },
    { id: 'ST_EXP_2', text: 'Comments', type: EvaluationQuestionType.STUDENT, section: EvaluationQuestionSection.EXPERIENCE, order: '2' },
    { id: 'ST_EXP_3', text: 'Organization', type: EvaluationQuestionType.STUDENT, section: EvaluationQuestionSection.EXPERIENCE, order: '3' }
  ];

  private endSubscription$ = new Subject();

  constructor(private fb: UntypedFormBuilder, private serviceRegistrationService: ServiceRegistrationService) {}

  ngOnInit(): void {
    this.experienceForm = this.fb.group({
      'ST_EXP_1': [null, [Validators.required]],
      'ST_EXP_2': [null, [Validators.required]],
      'ST_EXP_3': [null, [Validators.required]]
    });

    this.getEvaluations();

    this.experienceForm.valueChanges.pipe(takeUntil(this.endSubscription$)).subscribe(() => {
      this.evaluationComplete.emit(this.experienceForm.valid);
    })
  }

  ngOnDestroy(): void {
    this.endSubscription$.next(true);
    this.endSubscription$.unsubscribe();
  }

  public setCurrentRating(value: string, type: string) {
    if(type === 'supervisor'){
      this.experienceForm.get('ST_EXP_1')?.setValue(value);
    } else if (type === 'organization') {
      this.experienceForm.get('ST_EXP_3')?.setValue(value);
    }
  }

  public getEvaluations() {
    this.serviceRegistrationService.getEvaluations(this.service.id).pipe(
      map(evaluations => evaluations.filter(evaluation => ((evaluation.question.type === EvaluationQuestionType.STUDENT) && (evaluation.question.section === EvaluationQuestionSection.EXPERIENCE)))))
      .subscribe(filteredEvals => {
        this.evaluations = filteredEvals;

        filteredEvals.forEach(evaluation => {
          this.experienceForm.get(evaluation.question.id)?.setValue(evaluation.response);
        })

        this.hasRetrievedEvals = true;
      })
  }

  public submitEvaluations() {
    if (this.evaluations.length > 0) {
      const updatedEvaluations: IEvaluation[] = [];

      Object.keys(this.experienceForm.controls).forEach((question, index) => {
        const value = this.experienceForm.get(question)?.value;
        const questionUsed = this.evaluationQuestions.find(evalQuestion => evalQuestion.id === question)!;
        const existingEvaluation = this.evaluations.find(evaluation => evaluation.question.order === questionUsed.order);

        if (existingEvaluation && existingEvaluation.response !== value) {
          updatedEvaluations.push({
            ...existingEvaluation,
            response: value
          });
        }
      });

      updatedEvaluations.forEach(evaluation => {
        this.serviceRegistrationService.updateEvaluations(this.service.id, evaluation).subscribe();
      });
    } else {
      const newEvaluations: Partial<IEvaluation>[] = Object.keys(this.experienceForm.controls).map((question, index) => {
        const questionUsed = this.evaluationQuestions.find(evalQuestion => evalQuestion.id === question)!;
        const value = this.experienceForm.get(question)?.value;

        return {
          serviceId: this.service.id,
          response: value,
          question: questionUsed
        };
      });

      this.serviceRegistrationService.addEvaluations(this.service.id, newEvaluations).subscribe();
    }
  }

  public getEvaluationRating(id: string) {
    let foundEval = this.evaluations.find(evaluation => evaluation.question.id === id);
    return foundEval ? foundEval.response : null;
  }
}
